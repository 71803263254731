export const LogoIcon = ({ width = 48, height = 48 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 48 48">
      <g clipPath="url(#clip0_387_2856)">
        <path
          d="M29.108 14.2943C31.3091 11.3486 30.7215 7.19191 27.7955 5.01005C24.8695 2.82819 20.713 3.4474 18.5118 6.39309L4.91135 24.5936C2.71016 27.5393 3.29776 31.696 6.2238 33.8778C9.14984 36.0597 13.3063 35.4405 15.5075 32.4948L29.108 14.2943Z"
          fill="#00954C"
        />
        <path
          d="M32.2575 32.4459C34.4587 35.3915 38.6151 36.0108 41.5412 33.8289C44.4672 31.647 45.0548 27.4903 42.8536 24.5447L29.2531 6.34417C27.052 3.39848 22.8955 2.77928 19.9695 4.96113C17.0434 7.14299 16.4558 11.2997 18.657 14.2454L32.2575 32.4459Z"
          fill="#074E9F"
        />
        <path
          d="M10.3541 44.4858L6.75677 39.052C6.64532 38.8838 6.50181 38.7396 6.33457 38.6276C6.16732 38.5157 5.97967 38.4383 5.7825 38.4H5.74052H5.6416H5.57265H5.41677H5.35082H5.24889H5.20692C5.00975 38.4383 4.8221 38.5157 4.65486 38.6276C4.48761 38.7396 4.3441 38.8838 4.23265 39.052L0.635326 44.4858C0.524507 44.6525 0.447378 44.8395 0.408344 45.0363C0.369309 45.233 0.369133 45.4355 0.407827 45.6323C0.44652 45.8291 0.523323 46.0163 0.633853 46.1832C0.744383 46.35 0.886477 46.4934 1.05202 46.605C1.21756 46.7166 1.4033 46.7942 1.59865 46.8335C1.79399 46.8728 1.99512 46.873 2.19053 46.8341C2.38595 46.7951 2.57183 46.7178 2.73756 46.6065C2.90329 46.4951 3.04563 46.3521 3.15645 46.1854L5.4977 42.6595L7.83896 46.1854C8.0632 46.5197 8.40948 46.7514 8.80245 46.8299C9.19543 46.9085 9.60332 46.8277 9.9374 46.605C10.1029 46.4934 10.2451 46.3501 10.3556 46.1832C10.4661 46.0163 10.5429 45.8291 10.5816 45.6323C10.6203 45.4355 10.6201 45.233 10.5811 45.0363C10.5421 44.8395 10.4649 44.6525 10.3541 44.4858Z"
          fill="#3B3B3C"
        />
        <path
          d="M19.912 46.0527C19.8853 46.0016 18.6704 44.2466 17.4377 42.4646C18.5843 40.809 19.6506 39.2678 19.6774 39.2196C19.7338 39.1173 19.8467 38.5363 19.2942 38.5363H17.5387C17.4549 38.5315 17.3714 38.549 17.2963 38.587C17.2213 38.625 17.1574 38.6823 17.1109 38.753C17.1109 38.753 16.6386 39.4303 15.9881 40.3725L14.8623 38.753C14.8161 38.6792 14.7513 38.6193 14.6744 38.5796C14.5976 38.5399 14.5117 38.5218 14.4256 38.5273H12.6701C12.1206 38.5273 12.2334 39.1082 12.2899 39.2106C12.3136 39.2587 13.38 40.8 14.5266 42.4556C13.3058 44.2466 12.0879 45.9895 12.0493 46.0527C11.9928 46.1551 11.88 46.7361 12.4325 46.7361H14.188C14.2717 46.7405 14.3551 46.7229 14.4301 46.6849C14.5051 46.6469 14.5691 46.5899 14.6157 46.5193L15.9762 44.5567L17.3367 46.5193C17.3831 46.5901 17.4471 46.6473 17.5221 46.6853C17.5971 46.7234 17.6806 46.7408 17.7644 46.7361H19.5199C20.0695 46.724 19.9685 46.143 19.912 46.0527Z"
          fill="#3B3B3C"
        />
        <path
          d="M25.5083 38.2737C23.0236 38.2737 21.3474 40.0296 21.3474 42.6422C21.3474 45.2548 22.9791 46.9895 25.5083 46.9895C28.0376 46.9895 29.6842 45.2427 29.6842 42.6422C29.6842 39.9902 28.0465 38.2737 25.5083 38.2737ZM27.1638 42.6422C27.1638 44.9278 25.9185 44.9278 25.5083 44.9278C25.0982 44.9278 23.8766 44.9278 23.8766 42.6422C23.8766 41.0891 24.4087 40.3353 25.5083 40.3353C26.608 40.3353 27.1638 41.0891 27.1638 42.6422Z"
          fill="#3B3B3C"
        />
        <path
          d="M34.8927 38.4H34.8425C32.7736 38.4 31.0743 39.6824 31.0743 41.9583V46.5792C31.0718 46.6157 31.0768 46.6524 31.089 46.6869C31.1011 46.7213 31.1202 46.7528 31.1449 46.7794C31.1696 46.806 31.1995 46.8271 31.2326 46.8414C31.2657 46.8556 31.3014 46.8627 31.3373 46.8622H33.2583C33.2989 46.8651 33.3395 46.8594 33.3778 46.8456C33.4161 46.8318 33.4512 46.8101 33.4808 46.7818C33.5105 46.7536 33.5341 46.7194 33.5503 46.6814C33.5664 46.6435 33.5747 46.6025 33.5746 46.5611V42.3466C33.5746 40.8264 34.4435 40.6126 34.8631 40.6126C35.2828 40.6126 36.1517 40.8264 36.1517 42.3466V46.5611C36.1516 46.6028 36.1599 46.644 36.1762 46.6822C36.1925 46.7204 36.2165 46.7547 36.2465 46.783C36.2765 46.8113 36.3119 46.8329 36.3505 46.8466C36.3892 46.8602 36.4301 46.8655 36.4709 46.8622H38.389C38.4249 46.8627 38.4606 46.8556 38.4937 46.8414C38.5268 46.8271 38.5567 46.806 38.5814 46.7794C38.6061 46.7528 38.6251 46.7213 38.6373 46.6869C38.6495 46.6524 38.6545 46.6157 38.652 46.5792V41.9553C38.6609 39.6824 36.9526 38.4 34.8927 38.4Z"
          fill="#3B3B3C"
        />
        <path
          d="M40.0531 44.3182C40.0531 44.3182 39.9629 43.9368 40.2998 43.9368H42.1046C42.1673 43.9304 42.2302 43.9466 42.282 43.9827C42.3339 44.0188 42.3712 44.0722 42.3874 44.1335C42.4987 44.6511 42.9048 45.0173 43.7891 45.0415C44.6735 45.0657 45.0585 44.8509 45.0585 44.3515C45.0585 44.2243 45.0134 44.0337 44.6314 43.843C44.1531 43.6575 43.6557 43.5266 43.1484 43.4525C41.1901 43.0863 40.1975 42.2419 40.1975 40.9737C40.1849 40.5582 40.2845 40.1471 40.4858 39.7841C40.6871 39.4211 40.9825 39.1199 41.3406 38.9125C41.3406 38.9125 42.1618 38.289 43.8373 38.2739C45.7023 38.2558 47.101 39.3181 47.3086 40.4773C47.3157 40.5143 47.3146 40.5523 47.3053 40.5888C47.2961 40.6252 47.2789 40.6592 47.255 40.6881C47.2311 40.7171 47.2011 40.7403 47.1672 40.7562C47.1333 40.7721 47.0963 40.7802 47.0589 40.78H45.0856C45.0339 40.7805 44.9828 40.7693 44.9359 40.7474C44.8891 40.7254 44.8477 40.6931 44.8149 40.6529L44.7848 40.6166C44.6463 40.4756 44.4792 40.3664 44.2949 40.2964C44.1106 40.2264 43.9135 40.1973 43.7169 40.211C42.7694 40.211 42.7694 40.5923 42.7694 40.7195C42.7694 40.8466 42.8326 41.0857 43.371 41.2854C43.9021 41.4492 44.4439 41.5756 44.9924 41.6638C46.737 42.0028 47.6184 42.8079 47.6184 44.0518C47.639 44.509 47.5385 44.9636 47.3271 45.3688C47.1156 45.7741 46.8009 46.1155 46.4152 46.3581C45.642 46.7945 44.7655 47.011 43.8794 46.9846C42.9635 47.0261 42.0553 46.799 41.2654 46.3309C40.9251 46.109 40.6398 45.8117 40.4312 45.4618C40.2227 45.1119 40.0964 44.7186 40.0621 44.3121C40.0621 44.3121 40.0621 44.2849 40.0621 44.2697"
          fill="#3B3B3C"
        />
      </g>
    </svg>
  )
}
