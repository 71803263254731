import React, { type FunctionComponent, type ReactNode, lazy, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { connect } from 'react-redux'
import { HelmetProvider } from 'react-helmet-async'

import WithPage from 'src/hoc/withPage'
import { Loading } from 'src/component'
import { type RootState } from 'src/redux/store'
import { intersection, isEmpty } from 'lodash'
import { worklifeManager } from 'src/permission'
import { getCookie } from 'src/helper/cookie'

const NotFoundPage = lazy(() => import('../page/not-found'))
const HomePage = lazy(() => import('../page/home'))
const LoginPage = lazy(() => import('../page/login'))
const CompanyList = lazy(() => import('../page/company-list'))
const MyAccountPage = lazy(() => import('../page/my-account'))
const MyTaskPage = lazy(() => import('../page/my-task'))
const MyTaskDetailPage = lazy(() => import('../page/my-task/detail'))
const MyTimeOffPage = lazy(() => import('../page/my-time-off'))
const RequestMyTimeOffPage = lazy(() => import('../page/my-time-off/request'))
const MyTimeOffDetailPage = lazy(() => import('../page/my-time-off/detail'))
const DownloadPage = lazy(() => import('../page/download'))
const DownloadFailedPage = lazy(() => import('../page/download/download-fail'))

type RouterData = {
  path: string
  permission?: string[]
  Component: FunctionComponent<any>
}

const helmetContext = {}

const dataRoutes: RouterData[] = [
  {
    path: '*',
    permission: [],
    Component: NotFoundPage,
  },
  {
    path: '/',
    permission: [],
    Component: HomePage,
  },
  {
    path: '/login',
    permission: [],
    Component: LoginPage,
  },
  {
    path: '/company',
    permission: [],
    Component: CompanyList,
  },
  {
    path: '/my-account',
    permission: [],
    Component: MyAccountPage,
  },
  {
    path: '/my-task',
    permission: [worklifeManager.viewMyTask],
    Component: MyTaskPage,
  },
  {
    path: '/my-task/:emplId/:code/detail',
    permission: [worklifeManager.viewMyTask],
    Component: MyTaskDetailPage,
  },
  {
    path: '/my-time-off',
    permission: [],
    Component: MyTimeOffPage,
  },
  {
    path: '/my-time-off/request',
    permission: [],
    Component: RequestMyTimeOffPage,
  },
  {
    path: '/my-time-off/:id/detail',
    permission: [],
    Component: MyTimeOffDetailPage,
  },
  {
    path: '/download',
    permission: [],
    Component: DownloadPage,
  },
  {
    path: '/download-failed',
    permission: [],
    Component: DownloadFailedPage,
  },
]

type RounterProps = {
  getPermissionList: string[]
}

const Router = (props: RounterProps) => {
  const renderRouteWithPermission = (routes: RouterData[]): ReactNode => {
    const permission = getCookie('permission') || []
    const mapRotueWithPermission = routes.map((data, i) => {
      const findPermission = intersection(
        !isEmpty(props?.getPermissionList)
          ? props?.getPermissionList
          : !isEmpty(permission)
          ? permission?.split(',')
          : [''],
        data.permission,
      )

      const { Component } = data
      if (!isEmpty(data.permission)) {
        if (findPermission.length === 0) {
          return null
        }
        return (
          <Route
            key={i}
            path={data.path}
            element={findPermission.length === 0 ? <NotFoundPage /> : <Component />}
          />
        )
      } else {
        return <Route key={i} path={data.path} element={<Component />} />
      }
    })
    return mapRotueWithPermission
  }

  return (
    <Suspense fallback={<Loading />}>
      <BrowserRouter>
        <WithPage>
          <HelmetProvider context={helmetContext}>
            <Routes>{renderRouteWithPermission(dataRoutes)}</Routes>
          </HelmetProvider>
        </WithPage>
      </BrowserRouter>
    </Suspense>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    getRole: state.user.permission,
    getPermissionList: state.user.permission,
  }
}

export default connect(mapStateToProps, null)(Router)
