import { clientAPI } from '../config'
import type { responseToken } from 'type/login-type'

export const LoginWithLineID = async (lineID: string) => {
  try {
    const res = await clientAPI('post', `/auth/login/line`, {
      lineId: lineID,
      refreshToken: 'test',
    })
    return res.data.data as responseToken
  } catch (e: any) {
    return {
      accessToken: '',
      refreshToken: '',
    } as responseToken
  }
}
