export const LAYOUT_HEADER = 80
export const LAYOUT_HEADER_MOBILE = 48
export const LAYOUT_FOOTER = 61.8
export const TAB_SIZE = 768

export const PRIMARY = '#074E9F'
export const WHITE = '#ffffff'
export const GRAY = '#667085'
export const GRAYSCALE = '#98A2B3'
export const BLACK = '#344054'
export const GRAYBORDER = '#E4E7EC'
export const RED = '#D92D20'
export const DEEP_WHITE = '#F6F7F9'

export const SIDE_BAR_WIDTH = '200px'
