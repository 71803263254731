import { createSlice } from '@reduxjs/toolkit'
import { detachMobile } from 'src/helper/app'

const isDetachMobile = detachMobile(navigator.userAgent)

const initialState = {
  toggleSidebar: isDetachMobile,
  isMobile: isDetachMobile,
}

const appSlice = createSlice({
  name: 'app',
  initialState: initialState,
  reducers: {
    setIsMobile: (state, action) => {
      state.isMobile = action.payload
    },
    toggleSidebar: (state, action) => {
      state.isMobile = action.payload
      state.toggleSidebar = detachMobile(navigator.userAgent)
    },
  },
})

export const { setIsMobile, toggleSidebar } = appSlice.actions

export default appSlice.reducer
