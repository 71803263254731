import { createSlice } from '@reduxjs/toolkit'
import { setCookie, removeCookieAllExceptLineId, removeCookie } from '../../helper/cookie'

interface userInitialState {
  isAuthenticated: boolean
  permission: any
  userProfile?: any
  userData: { image: string; name: string; email: string; nameEng: string }
}

const userInitialState: userInitialState = {
  isAuthenticated: false,
  permission: [],
  userProfile: [],
  userData: { image: '', name: '', email: '', nameEng: '' },
}

const userSlice = createSlice({
  name: 'user',
  initialState: userInitialState,
  reducers: {
    isLogin: (state: userInitialState, action) => {
      setCookie('accessToken', action.payload.accessToken)
      setCookie('refreshToken', action.payload.refreshToken)
      setCookie('username', action.payload.username)
      //setCookie('user_profile', action.payload)
      setCookie('azureIdToken', action.payload.azureIdToken)
      setCookie('azureIdToken', action.payload.azureIdToken)
      state.userProfile = action.payload
      state.isAuthenticated = true
    },
    isAuth: (state: userInitialState) => {
      state.isAuthenticated = true
    },
    Logout(state: userInitialState) {
      removeCookieAllExceptLineId()
      removeCookie('permission')
      state.isAuthenticated = false
    },
    SetPermission: (state: userInitialState, action) => {
      state.isAuthenticated = true
      state.permission = action.payload
      setCookie('permission', action?.payload?.toString())
    },
    setUserData: (state, action) => {
      state.userData = action.payload
    },
  },
})

export const { isLogin, Logout, SetPermission, isAuth, setUserData } = userSlice.actions

export default userSlice.reducer
