import { LogLevel, type Configuration } from '@azure/msal-browser'
import { createSlice } from '@reduxjs/toolkit'
import { getCookie, setCookie } from 'src/helper/cookie'

const defaultAuthority = 'https://login.microsoftonline.com/'
const configAzure: Configuration = {
  auth: {
    clientId: getCookie('clientId'),
    authority: getCookie('tenentId'),
    redirectUri: '/',
    navigateToLoginRequestUrl: true,
  },
  cache: {
    // cacheLocation: 'sessionStorage',
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      logLevel: LogLevel.Error,
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message)
            return
          case LogLevel.Info:
            console.info(message)
            return
          case LogLevel.Verbose:
            console.debug(message)
            return
          case LogLevel.Warning:
            console.warn(message)
            return
          default:
            return
        }
      },
    },
  },
}

const initialState = {
  config: configAzure,
}

const companySlice = createSlice({
  name: 'company',
  initialState: initialState,
  reducers: {
    SetAzureAD: (state: typeof initialState, action) => {
      setCookie('clientId', action.payload.clientId)
      setCookie('tenentId', defaultAuthority + action.payload.tenentId)
      state.config.auth.clientId = action.payload.clientId
      state.config.auth.authority = defaultAuthority + action.payload.tenentId
    },
  },
})

export const { SetAzureAD } = companySlice.actions

export default companySlice.reducer
