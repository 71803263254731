import React from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import styled from 'styled-components'

import { PRIMARY } from 'src/constant'

const loadingIcon = <LoadingOutlined style={{ fontSize: 100, color: PRIMARY }} spin />

const LoadingContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`
const SpinLoading: React.FC = () => {
  return (
    <LoadingContainer>
      <Spin indicator={loadingIcon} />
    </LoadingContainer>
  )
}

export default SpinLoading
