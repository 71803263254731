import * as React from 'react'
import { PRIMARY } from 'src/constant/index'

type appContextType = {
  appContext: string
  langContext: string
  themeColor: string
  setAppContext?: (data: appContextType) => void
  setTheme?: (data: appContextType) => void
}

export const appInitialContext: appContextType = {
  appContext: 'th',
  langContext: 'th',
  themeColor: PRIMARY,
}

export const AppContext = React.createContext(appInitialContext)
