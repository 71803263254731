import styled from 'styled-components'
import { GRAY, PRIMARY, SIDE_BAR_WIDTH } from 'src/constant'

export const MenuContentContainer = styled.div`
  width: ${SIDE_BAR_WIDTH};
  transition: all 0.3s;
  .title-menu {
    color: ${GRAY};
    padding: 20px 16px;
  }

  .logout-box {
    position: absolute;
    bottom: 0px;
    height: 60px;
    display: flex;
    align-items: center;
    border-top: 1px solid #e4e7ec;
    width: ${SIDE_BAR_WIDTH};
  }

  .ant-menu-inline > .ant-menu-item,
  .ant-menu-vertical > .ant-menu-item,
  .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
    display: flex;
    align-items: center;
    padding-right: 0px;
  }

  .ant-menu .ant-menu-title-content {
    margin-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ant-menu-light .ant-menu-item-selected {
    border-left: 3px solid;
    font-weight: 600;
    color: ${PRIMARY};
  }
`
