import { useState, memo } from 'react'
import { compose } from '@reduxjs/toolkit'
import { ConfigProvider } from 'antd'
import enUS from 'antd/locale/en_US'
import thTH from 'antd/locale/th_TH'

import { AppContext, appInitialContext } from '../context/app'
import WithAuth from './withAuth'

import GlobalStyle from 'src/style/global'
import { useTranslation } from 'react-i18next'

const WithPage = memo(({ children }: any) => {
  const [appContext, setAppContext] = useState(appInitialContext)
  const { i18n } = useTranslation()

  const Theme = {
    token: {
      colorPrimary: appContext.themeColor,
      fontFamily: 'IBMPlexSansThai',
      // colorInfo: 'blue',
      // colorSuccess: 'green',
      // colorError: 'red',
      // colorWarning: 'yellow',
      // colorTextBase: 'grey',
      // colorText: 'grey',
      // colorTextHeading: 'darkgrey',
      // colorLink: 'green',
      // colorLinkHover: 'lightgreen',
      // colorHighlight: '#f00',
      fontSize: 16,
      // borderRadius: 3,
    },
    components: {},
  }

  return (
    <AppContext.Provider
      value={{
        ...appContext,
        setAppContext: (data) => {
          setAppContext(data)
        },
      }}
    >
      <ConfigProvider theme={Theme} locale={i18n.language === 'th' ? thTH : enUS}>
        <>
          <GlobalStyle colorPrimary={appContext.themeColor} />
          <WithAuth>{children}</WithAuth>
        </>
      </ConfigProvider>
    </AppContext.Provider>
  )
})
WithPage.displayName = 'WithPage'

export default compose(WithPage)
