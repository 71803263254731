import axios from 'axios'
import { getCookie } from 'src/helper/cookie'

export const queryConfig = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      onError: (error: any) => {
        if (error.response.data.statusCode === 401) {
          window.location.replace('/my-account')
        }
      },
    },
  },
}

const createCilentAPI = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: 'application/json',
  },
  responseType: 'json',
})

export const clientAPI = async (method: string, url: string, body?: any) => {
  const accessToken = getCookie('accessToken')
  const response = await createCilentAPI({
    method,
    url,
    data: body,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
  return response
}

export const clientAPIDownload = async (method: string, url: string, body?: any) => {
  const accessToken = getCookie('accessToken')
  const createCilentAPIDownLoad = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Accept: '*',
    },
    responseType: 'blob',
  })

  const response = await createCilentAPIDownLoad({
    method,
    url,
    data: body,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
  return response
}
