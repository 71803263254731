import React, { useMemo, memo } from 'react'
import { bindActionCreators } from 'redux'
import { connect, useSelector, useDispatch } from 'react-redux'

import LayoutContainer from 'src/component/layout'
import { toggleSidebar } from 'src/redux/app/reducer'
import type { AppDispatch, RootState } from 'src/redux/store'

type props = {
  children: React.ReactNode
  menu?: boolean
  isAuthenticated?: boolean
}

function areEqual(prevProps: props, nextProps: props) {
  return prevProps.menu === nextProps.menu
}

const HocWithLayout = memo(({ children, isAuthenticated }: props) => {
  HocWithLayout.displayName = 'HocWithLayout'

  const dispatch = useDispatch()
  const isMobile = useSelector((state: RootState) => state.app.isMobile)

  useMemo(() => {
    if (isMobile) dispatch(toggleSidebar(true))
  }, [dispatch, isMobile])

  return <LayoutContainer menu={isAuthenticated}>{children}</LayoutContainer>
})

function mapStateToProps(state: RootState) {
  return {
    isMobile: state.app.isMobile,
    isAuthenticated: state.user.isAuthenticated,
  }
}

function mapDispatchToProps(dispatch: AppDispatch) {
  return {
    toggleSidebar: bindActionCreators(toggleSidebar, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(HocWithLayout, areEqual))
