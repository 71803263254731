import { type FunctionComponent, useContext, useEffect } from 'react'
import { Layout, Select, Row, Avatar } from 'antd'
import { useDispatch, connect, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { UserOutlined } from '@ant-design/icons'
import styled from 'styled-components'

import {
  swithLanguage,
  toggleCollapasedNav,
  toggleOpenDrawer,
  windowWidth,
} from 'src/redux/setting/reducer'
import { AppContext, appInitialContext } from 'src/context/app'
import { type RootState } from 'redux/store'

import {
  MenuIcon,
  FlagEnIcon,
  FlagThIcon,
  SelectSuffixIcon,
  CloseMenuIcon,
} from 'src/component/icon'
import { BLACK } from 'src/constant/index'

const { Header } = Layout

const LeftContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 20px;
`

const RightContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 30px;

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 0px;
  }

  .name {
    font-weight: 600;
    font-size: 14px;
    color: ${BLACK};
    height: 40px;
    display: flex;
    margin-top: -5px;
    margin-bottom: 10px;
  }

  .email {
    height: 40px;
    display: flex;
    align-items: end;
  }

  .image {
    height: 44px;
    width: 44px;
    border-radius: 25px;
  }

  .active-dot {
    background: #07a721;
    border: 2px solid #f5fafe;
    border-radius: 99px;
    width: 16px;
    height: 16px;
    position: absolute;
    bottom: 0;
    margin-left: 30px;
  }

  .content-center {
    display: flex;
    align-items: center;
    height: 100%;
  }
`

const { Option } = Select

const language = [
  {
    value: 'th',
    label: 'TH',
    icon: <FlagThIcon />,
  },
  {
    value: 'en',
    label: 'EN',
    icon: <FlagEnIcon />,
  },
]

const TopbarComponent: FunctionComponent<{
  logoSize: number
  isAuthenticated: boolean
}> = ({ logoSize, isAuthenticated }) => {
  const appContext = useContext(AppContext)
  const dispatch = useDispatch()
  const { i18n } = useTranslation()
  const navCollapsed = useSelector((state: RootState) => state.setting.navCollapsed)
  const isOpenDrawer = useSelector((state: RootState) => state.setting.isOpenDrawer)
  const isMobile = useSelector((state: RootState) => state.app.isMobile)
  const userData = useSelector((state: RootState) => state.user.userData)
  const isCompanyPage =
    location.pathname === '/company' ||
    location.search.includes('company') ||
    (location.hash.includes('#code=') && location.pathname === '/')

  const SelectLanguage = (lang: string) => {
    if (appContext.setAppContext === undefined) {
      return
    }
    dispatch(
      swithLanguage({
        locale: {
          languageID: 'english',
          locale: lang,
          name: 'English',
          icon: 'us',
        },
      }),
    )
    localStorage.setItem('language', lang)
    i18n.changeLanguage(lang)
    appContext.setAppContext({
      ...appInitialContext,
      appContext: lang,
      langContext: lang,
    })
  }

  const renderIcons = (menu: boolean) => {
    if (isMobile) {
      return menu ? <CloseMenuIcon /> : <MenuIcon />
    } else {
      return menu ? <MenuIcon /> : <CloseMenuIcon />
    }
  }

  useEffect(() => {
    window.addEventListener('resize', () => {
      dispatch(windowWidth(window.innerWidth))
    })
  }, [dispatch])

  return (
    <Header
      style={{ display: 'flex', justifyContent: 'space-between', position: 'relative' }}
    >
      <LeftContainer>
        {isAuthenticated && !isCompanyPage && (
          <span
            style={{ display: 'flex', cursor: 'pointer' }}
            onClick={() => {
              if (isMobile) {
                dispatch(toggleOpenDrawer(!isOpenDrawer))
              } else {
                dispatch(toggleCollapasedNav(!navCollapsed))
              }
            }}
          >
            {renderIcons(isMobile ? isOpenDrawer : navCollapsed)}
          </span>
        )}
        <img src="/HRworklife.svg" width={logoSize} height={logoSize} />
        {/* <LogoIcon width={logoSize} height={logoSize} /> */}
      </LeftContainer>
      <RightContainer>
        <Select
          value={i18n.language}
          onSelect={SelectLanguage}
          suffixIcon={<SelectSuffixIcon />}
          optionLabelProp="children"
          bordered={false}
        >
          {language.map((data, i) => {
            return (
              <Option key={i} value={data.value}>
                <Row>
                  {data.icon}
                  <span
                    style={{
                      fontFamily: 'IBMPlexSansThai-SemiBold',
                      lineHeight: '32px',
                      paddingInlineStart: '6px',
                    }}
                  >
                    {data.label}
                  </span>
                </Row>
              </Option>
            )
          })}
          {/* <Select defaultValue={appContext.langContext} onSelect={SelectLanguage}> */}
        </Select>
        {isAuthenticated && !isCompanyPage && (
          <div className="content-center">
            <div className="content-center">
              {userData?.image ? (
                <img src={userData?.image} className="image" />
              ) : (
                <Avatar
                  icon={<UserOutlined />}
                  className="image"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#dadada',
                  }}
                />
              )}
              <div className="active-dot" style={{ marginBottom: isMobile ? 2 : 16 }} />
            </div>
            {!isMobile && (
              <div style={{ height: '100%', marginLeft: 12, maxWidth: 200 }}>
                <span className="name">
                  {i18n.language === 'en' ? userData.nameEng : userData.name}
                </span>
                <span className="email">{userData?.email || '-'}</span>
              </div>
            )}
          </div>
        )}
      </RightContainer>
    </Header>
  )
}

function mapStateToProps(state: RootState) {
  return {
    isAuthenticated: state.user.isAuthenticated,
  }
}

export default connect(mapStateToProps)(TopbarComponent)
