export const LockIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M12 2C9.79971 2 8 3.79971 8 6V8H6.25C5.01625 8 4 9.01625 4 10.25V19.75C4 20.9838 5.01625 22 6.25 22H17.75C18.9838 22 20 20.9838 20 19.75V10.25C20 9.01625 18.9838 8 17.75 8H16V6C16 3.79971 14.2003 2 12 2ZM12 3.5C13.3897 3.5 14.5 4.61029 14.5 6V8H9.5V6C9.5 4.61029 10.6103 3.5 12 3.5ZM6.25 9.5H17.75C18.1733 9.5 18.5 9.82675 18.5 10.25V19.75C18.5 20.1733 18.1733 20.5 17.75 20.5H6.25C5.82675 20.5 5.5 20.1733 5.5 19.75V10.25C5.5 9.82675 5.82675 9.5 6.25 9.5ZM12 13.5C11.6022 13.5 11.2206 13.658 10.9393 13.9393C10.658 14.2206 10.5 14.6022 10.5 15C10.5 15.3978 10.658 15.7794 10.9393 16.0607C11.2206 16.342 11.6022 16.5 12 16.5C12.3978 16.5 12.7794 16.342 13.0607 16.0607C13.342 15.7794 13.5 15.3978 13.5 15C13.5 14.6022 13.342 14.2206 13.0607 13.9393C12.7794 13.658 12.3978 13.5 12 13.5Z"
        fill="#667085"
      />
    </svg>
  )
}
