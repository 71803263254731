import styled from 'styled-components'
import { GRAY, SIDE_BAR_WIDTH } from 'src/constant/index'

export default styled.div<{ minusWidth: number; footerDirection?: string }>`
  height: 100%;
  width: 100vw;

  .layout-footer-content {
    height: 180px;
    display: flex;
    flex-direction: row;
    font-size: 12px;
    justify-content: space-between;
    text-align: center;
    color: ${GRAY};
    padding: 16px 0px 30px;
    margin: 0px 20px;
    border-top: 1px solid #e4e7ec;

    @media only screen and (max-width: 767.99px) {
      height: 140px;
    }

    @media only screen and (max-width: 540px) {
      height: 150px;
      display: unset;
    }
  }

  .layout-content-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
  }

  .main-content-wrapper {
    height: 100%;
    width: calc(100% - ${SIDE_BAR_WIDTH});
    background: #f6f7f9;
  }

  .content-wrapper {
    height: 100%;
    padding: 24px 20px;
    overflow: auto;
  }
`
