import { createSlice } from '@reduxjs/toolkit'

const initialStateSettings = {
  navCollapsed: false,
  navStyle: '',
  themeType: '',
  pathname: '',
  width: window.innerWidth,
  locale: {
    languageID: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us',
  },
  isOpenDrawer: false,
}

const settingSlice = createSlice({
  name: 'setting',
  initialState: initialStateSettings,
  reducers: {
    toggleCollapasedNav: (state, action) => {
      state.navCollapsed = action.payload
    },
    setPathName: (state, action) => {
      state.navCollapsed = action.payload
    },
    windowWidth: (state, action) => {
      state.width = action.payload
    },
    themeType: (state, action) => {
      state.themeType = action.payload
    },
    navStyle: (state, action) => {
      state.navStyle = action.payload
    },
    swithLanguage: (state, action) => {
      state.locale = action.payload
    },
    toggleOpenDrawer: (state, action) => {
      state.isOpenDrawer = action.payload
    },
  },
})

export const {
  toggleCollapasedNav,
  setPathName,
  windowWidth,
  themeType,
  navStyle,
  swithLanguage,
  toggleOpenDrawer,
} = settingSlice.actions

export default settingSlice.reducer
