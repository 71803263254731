import { combineReducers } from 'redux'
import userReducer from './user/reducer'
import settingReducer from './setting/reducer'
import appReducer from './app/reducer'
import companyReducer from './company/reducer'
import timeOffReducer from './time-off/reducer'

const rootReducer = combineReducers({
  user: userReducer,
  setting: settingReducer,
  app: appReducer,
  company: companyReducer,
  timeoff: timeOffReducer,
})

export default rootReducer
