import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { QueryClientProvider, QueryClient } from 'react-query'

import { queryConfig } from 'src/api/config'
import reportWebVitals from './reportWebVitals'
import { store } from './redux/store'
import Route from './route'

import 'src/helper/i18n'
import './style/index.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const queryClient = new QueryClient(queryConfig)

root.render(
  // <StrictMode>
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <Route />
    </QueryClientProvider>
  </Provider>,
  // </StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
