import { useRef, StrictMode } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector, connect } from 'react-redux'
import { Menu, Layout, Drawer } from 'antd'

import { useTranslation } from 'react-i18next'
import { toggleCollapasedNav, toggleOpenDrawer } from 'src/redux/setting/reducer'

import { type RootState } from 'src/redux/store'
import { MenuContentContainer } from './styled'
import { MyAccountMenuIcon, MyTaskMenuIcon, MyTimeOffMenuIcon } from 'src/component/icon'

import { worklifeManager } from 'src/permission'
import { find } from 'lodash'

// const { useToken } = theme
const { Sider } = Layout

type SideBarProps = {
  permission: string[]
}

const SidebarComponent = (props: SideBarProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const menuRef = useRef(null)
  const navCollapsed = useSelector((state: RootState) => state.setting.navCollapsed)
  const isOpenDrawer = useSelector((state: RootState) => state.setting.isOpenDrawer)
  const isMobile = useSelector((state: RootState) => state.app.isMobile)

  const onToggleCollapsedNav = () => {
    dispatch(toggleCollapasedNav(!navCollapsed))
  }

  const onToggleOpenDrawer = () => {
    dispatch(toggleOpenDrawer(!isOpenDrawer))
  }

  const checkActiveMenu = () => {
    if (window.location.pathname.match(/^\/(my-account)(\/.*)?/)) {
      return 'my-account'
    }
    if (window.location.pathname.match(/^\/(my-task)(\/.*)?/)) {
      return 'my-task'
    }
    if (window.location.pathname.match(/^\/(my-time-off)(\/.*)?/)) {
      return 'my-time-off'
    }
    return ''
  }

  const checkPermissions = (permission: string) => {
    const findPermission = find(props?.permission, (e) => e === permission)
    return findPermission
  }

  const SideBarMenu = () => {
    return (
      <MenuContentContainer>
        <div className="title-menu">{t('menu.menu-title')}</div>
        <StrictMode>
          <Menu
            ref={menuRef}
            selectedKeys={[checkActiveMenu()]}
            defaultSelectedKeys={[checkActiveMenu()]}
            onClick={() => isMobile && dispatch(toggleOpenDrawer(!isOpenDrawer))}
          >
            <Menu.Item
              key="my-account"
              icon={<MyAccountMenuIcon activeKey={checkActiveMenu()} />}
              onClick={() => {
                navigate('/my-account')
              }}
            >
              {t('menu.my-account')}
            </Menu.Item>
            {checkPermissions(worklifeManager.viewMyTask) && (
              <Menu.Item
                key="my-task"
                icon={<MyTaskMenuIcon activeKey={checkActiveMenu()} />}
                onClick={() => {
                  navigate('/my-task')
                }}
              >
                {t('menu.my-task')}
              </Menu.Item>
            )}
            <Menu.Item
              key="my-time-off"
              icon={<MyTimeOffMenuIcon activeKey={checkActiveMenu()} />}
              onClick={() => {
                navigate('/my-time-off')
              }}
            >
              {t('menu.my-time-off')}
            </Menu.Item>
          </Menu>
        </StrictMode>
      </MenuContentContainer>
    )
  }

  return (
    <>
      {isMobile ? (
        <Drawer
          placement="left"
          closable={false}
          onClose={onToggleOpenDrawer}
          open={isOpenDrawer}
        >
          <SideBarMenu />
        </Drawer>
      ) : (
        <Sider
          trigger={null}
          collapsible
          collapsed={navCollapsed}
          onCollapse={() => onToggleCollapsedNav()}
          className={navCollapsed ? 'hidden-sidebar' : ''}
          style={{ maxWidth: navCollapsed ? 0 : '' }}
        >
          <SideBarMenu />
        </Sider>
      )}
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    permission: state.user.permission,
  }
}

export default connect(mapStateToProps, null)(SidebarComponent)
