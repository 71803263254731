import { configureStore } from '@reduxjs/toolkit'
import thunkMiddleware from 'redux-thunk'
import reducers from './reducer'

const middlewares = [thunkMiddleware]

export const store = configureStore({
  reducer: reducers,
  middleware: middlewares,
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
