import React, { useEffect, useContext } from 'react'
import { Layout } from 'antd'
import { useSelector, connect, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { isUndefined } from 'lodash'

import { swithLanguage } from 'src/redux/setting/reducer'
import { AppContext, appInitialContext } from 'src/context/app'
import { setIsMobile } from 'src/redux/app/reducer'

import type { RootState } from 'src/redux/store'
import { LAYOUT_HEADER, LAYOUT_HEADER_MOBILE, TAB_SIZE } from 'src/constant'
import { Version } from 'src/helper/env'
import { setUserData } from 'src/redux/user/reducer'

import Sidebar from './sidebar'
import Topbar from './topbar'
import Styled from './styled'
import ScrollToTop from 'src/helper/scrollToTop'

const { Content } = Layout

const getContainerClass = (navStyle: string) => {
  switch (navStyle) {
    case 'NAV_STYLE_ABOVE_HEADER':
      return 'main-content-wrapper header'
    default:
      return 'main-content-wrapper'
  }
}

const getNavStyles = (navStyle: string, logoSize: number) => {
  switch (navStyle) {
    default:
      return <Topbar logoSize={logoSize} />
  }
}

const getSidebar = (width: number) => {
  if (width < TAB_SIZE) {
    return <Sidebar />
  }
  return <Sidebar />
}

const MainApp = (props: any) => {
  const appContext = useContext(AppContext)
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const navStyle = useSelector((state: RootState) => state.setting.navStyle)
  const width = useSelector((state: RootState) => state.setting.width)
  const isDownloadPage: boolean = window.location.pathname.includes('/download')

  const layout =
    width > TAB_SIZE
      ? {
          logoSize: 35,
          headerSize: LAYOUT_HEADER,
          footerDirection: 'row',
        }
      : {
          logoSize: 24,
          headerSize: LAYOUT_HEADER_MOBILE,
          footerDirection: 'column',
        }

  const { children } = props
  const footerTextVersion = `${t('common.version')} ${Version}`
  const footerText = '© 2022 AXONS. All Rights Reserved.'

  useEffect(() => {
    if (window.innerWidth <= 767.99) {
      dispatch(setIsMobile(true))
      return
    }
    dispatch(setIsMobile(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth])

  useEffect(() => {
    if (isUndefined(appContext.setAppContext)) {
      return
    }
    const language = localStorage?.getItem('language') || 'th'
    const image = localStorage?.getItem('image') || ''
    const name = localStorage?.getItem('name')
    const nameEng = localStorage?.getItem('nameEng')
    const email = localStorage?.getItem('email')
    dispatch(
      swithLanguage({
        locale: {
          languageID: 'english',
          locale: language,
          name: 'English',
          icon: 'us',
        },
      }),
    )
    dispatch(
      setUserData({
        image: image,
        name: name,
        nameEng: nameEng,
        email: email,
      }),
    )
    i18n.changeLanguage(language)
    appContext.setAppContext({
      ...appInitialContext,
      appContext: language,
      langContext: language,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Styled minusWidth={layout.headerSize} footerDirection={layout.footerDirection}>
      {!isDownloadPage && getNavStyles(navStyle, layout.logoSize)}
      <Content className={`layout-content-wrapper`}>
        {props.isAuthenticated && getSidebar(width)}
        <Layout className={`${getContainerClass(navStyle)}`}>
          <div id="content-wrapper" className="content-wrapper">
            {children}
          </div>
          {!isDownloadPage && (
            <div className="layout-footer-content">
              <div style={{ marginBottom: 10 }}>{footerTextVersion}</div>
              <div>{footerText}</div>
            </div>
          )}
        </Layout>
      </Content>
      <ScrollToTop />
    </Styled>
  )
}

function mapStateToProps(state: RootState) {
  return {
    isMobile: state.app.isMobile,
    isSidebar: state.app.toggleSidebar,
    getPermissionList: state.user.permission,
    isAuthenticated: state.user.isAuthenticated,
  }
}

export default connect(mapStateToProps)(React.memo(MainApp))
