import { createSlice } from '@reduxjs/toolkit'

const initialStateTimeOff = {
  absenceAbstake: [],
}

const timeOffSlice = createSlice({
  name: 'timeoff',
  initialState: initialStateTimeOff,
  reducers: {
    setAbsenceAbstake: (state, action) => {
      state.absenceAbstake = action.payload
    },
  },
})

export const { setAbsenceAbstake } = timeOffSlice.actions

export default timeOffSlice.reducer
