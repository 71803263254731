import {
  useEffect,
  useCallback,
  useState,
  useTransition,
  memo,
  type ReactNode,
} from 'react'
import { bindActionCreators } from '@reduxjs/toolkit'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { liff } from '@line/liff'
import { MsalProvider } from '@azure/msal-react'
import { PublicClientApplication } from '@azure/msal-browser'
import { isAuth, isLogin, Logout, SetPermission } from 'src/redux/user/reducer'
import type { AppDispatch, RootState } from 'src/redux/store'
import WithLayout from './withLayout'
import { getCookie, setCookie } from '../helper/cookie'
import { parseJWT } from '../helper/jwt'
import { LoginWithLineID } from '../api/line-login'
import { isUndefined } from 'lodash'

type WithAuthProps = {
  isAuthenticated: boolean
  permission: string[]
  isAuth: () => void
  children: ReactNode
}
const WithAuth = (props: WithAuthProps) => {
  const { init, isLoggedIn, login, getProfile } = liff
  const navigate = useNavigate()
  const [isPending] = useTransition()
  const dispatch = useDispatch()
  const location = useLocation()
  const lineID: string = getCookie('lineID') || ''
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const companyConfig = useSelector((state: RootState) => state.company.config)
  const msalInstance = new PublicClientApplication(companyConfig)
  const isCompanyPage =
    location.pathname === '/company' ||
    location.search.includes('company') ||
    (location.hash.includes('#code=') && location.pathname === '/')
  const isDownloadPage: boolean = location.pathname.includes('/download')

  const checkPermission = (response: any) => {
    const findModule = response?.programList?.find((e: any) => e?.moduleName === 'Menu')
    return !isUndefined(findModule)
      ? findModule?.programList?.map((e: any) => e.code)
      : []
  }

  const redirectToHome = useCallback(() => {
    dispatch(Logout())
    setIsLoading(false)
    if (!isCompanyPage) {
      navigate('/company')
    }
  }, [dispatch, isCompanyPage, navigate])

  // get token by lineID || auto login with lineID
  const getTokenAccessWithLineID = useCallback(
    async (lineId: string) => {
      const res = await LoginWithLineID(lineId)
      // if no access token response
      if (!res.accessToken) {
        redirectToHome()
        return
      }
      // if access token response
      dispatch(
        isLogin({
          accessToken: res?.accessToken,
          refreshToken: res?.refreshToken,
        }),
      )
      dispatch(SetPermission(checkPermission(res)))
      props.isAuth()
      navigate('/')
      setIsLoading(false)
    },
    [dispatch, navigate, props, redirectToHome],
  )

  const checkPremission = useCallback(
    async (lineId: string) => {
      const accessToken = getCookie('accessToken') || ''
      const isUndefinedLine: boolean = !lineId || lineId === 'undefined'
      const isUndefinedAccessToken: boolean = !accessToken || accessToken === 'undefined'
      if ((isUndefinedLine && isUndefinedAccessToken) || isCompanyPage) {
        redirectToHome()
        return
      }
      // check token if not undefined
      else if (!isUndefinedAccessToken) {
        const payload = parseJWT(accessToken)
        const currentTime = Date.now() / 1000
        // if token expired
        if (payload.exp < currentTime) {
          getTokenAccessWithLineID(lineId)
        }
        // if token not expired
        else {
          props.isAuth()
        }
      }
      // check token if undefined
      else {
        getTokenAccessWithLineID(lineId)
      }
      setIsLoading(false)
    },
    [getTokenAccessWithLineID, isCompanyPage, props, redirectToHome],
  )

  const initLineLiff = useCallback(async () => {
    try {
      await init({ liffId: process.env.REACT_APP_LIFF_ID as string })
      if (isLoggedIn()) {
        const profile = await getProfile()
        setCookie('lineID', profile.userId)
        checkPremission(profile.userId)
        setIsLoading(false)
        return
      } else {
        login()
      }
    } catch (error) {
      console.error('liff init error', error)
      //throw error
    }
  }, [checkPremission, getProfile, init, isLoggedIn, login])

  useEffect(() => {
    let unmount = false
    if (!unmount) {
      if (isDownloadPage) {
        setIsLoading(false)
        return
      } else {
        const undefinedLineID: boolean =
          !lineID || lineID === '' || lineID === 'undefined'
        if (undefinedLineID) {
          initLineLiff()
          setIsLoading(false)
          return
        }
        checkPremission(lineID)
      }
    }
    return () => {
      unmount = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineID])

  const handlePermissions = () => {
    const permission = getCookie('permission')
    if (permission) {
      dispatch(SetPermission(permission.split(',')))
    }
  }

  useEffect(() => {
    handlePermissions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return !isPending && !isLoading ? (
    <MsalProvider instance={msalInstance}>
      <WithLayout menu={props.isAuthenticated}>{props.children}</WithLayout>
    </MsalProvider>
  ) : (
    <></>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    isAuthenticated: state.user.isAuthenticated,
    permission: state.user.permission,
  }
}

const mapDispatchToProps = (dispacth: AppDispatch) => {
  return {
    setPermission: bindActionCreators(SetPermission, dispacth),
    isAuth: bindActionCreators(isAuth, dispacth),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(WithAuth))
