import Cookies from 'js-cookie'

const storage = {
  get(key: string) {
    const item = (() => {
      if (window.sessionStorage.getItem(key)) return window.sessionStorage.getItem(key)
      if (window.localStorage.getItem(key)) return window.localStorage.getItem(key)
      else return Cookies.get(key)
    })()
    try {
      return JSON.parse(item as string)
    } catch (e) {
      return item
    }
  },
  set(key: string, value: any) {
    const item = (() => {
      if (typeof value === 'object') return JSON.stringify(value)
      return value
    })()
    Cookies.set(key, item || '')
    if (window.sessionStorage) window.sessionStorage.setItem(key, item || '')
    if (window.localStorage) window.localStorage.setItem(key, item || '')
  },
  remove(key: string) {
    Cookies.remove(key)
    if (window.sessionStorage) window.sessionStorage.removeItem(key)
    if (window.localStorage) window.localStorage.removeItem(key)
  },
  removeAll() {
    if (window.sessionStorage) window.sessionStorage.clear()
    if (window.localStorage) window.localStorage.clear()
  },
}

export const getCookie = (key: string) => {
  return storage.get(key)
}

export const setCookie = (key: string, value: any) => {
  storage.set(key, value)
}

export const removeCookie = (key: string) => {
  storage.remove(key)
}

export const removeCookieAll = () => {
  storage.removeAll()
}

export const removeCookieAllExceptLineId = () => {
  const lineID = getCookie('lineID')
  const clientId = getCookie('clientId')
  const tenentId = getCookie('tenentId')
  storage.removeAll()
  setCookie('lineID', lineID)
  setCookie('clientId', clientId)
  setCookie('tenentId', tenentId)
}
