import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import translationEN from './translation/en.json'
import translationTH from './translation/th.json'

export const resources = {
  en: {
    translation: translationEN,
  },
  th: {
    translation: translationTH,
  },
}

i18next.use(initReactI18next).init({
  lng: 'en', // if you're using a language detector, do not define the lng option
  // debug: true,
  resources,
})
